export const treePositions = [
    [ 493, 2, 250 ],
    [ 505, 2, 240 ],
    [ 669, 2, 256 ],
    [ 499, 2, 264 ],
    [ 657, 2, 153 ],
    [ 485, 2, 241 ],
    [ 489, 2, 237 ],
    [ 630, 2, 257 ],
    [ 687, 2, 224 ],
    [ 518, 2, 233 ], // 10
    [ 700, 2, 253 ],
    [ 505, 2, 265 ],
    [ 510, 2, 269 ],
    [ 478, 2, 243 ],
    [ 477, 2, 232 ],
    [ 658, 2, 73 ],
    [ 624, 2, 233 ],
    [ 684, 2, 191 ],
    [ 522, 2, 203 ],
    [ 526, 2, 215 ], // 20
    [ 550, 2, 238 ],
    [ 558, 2, 251 ],
    [ 532, 2, 271 ],
    [ 522, 2, 261 ],
    [ 477, 2, 232 ],
    [ 481, 2, 230 ],
    [ 511, 2, 216 ],
    [ 513, 2, 211 ],
    [ 522, 2, 203 ],
    [ 558, 2, 207 ], // 30
    [ 600, 2, 263 ],
    [ 560, 2, 267 ],
    [ 594, 2, 270 ],
    [ 554, 2, 260 ],
    [ 540, 2, 266 ],
    [ 491, 2, 190 ],
    [ 483, 2, 178 ],
    [ 486, 2, 164 ],
    [ 496, 2, 127 ],
    [ 502, 2, 83 ],
    [ 496, 2, 70 ],
    [ 495, 2, 41 ],
    [ 495, 2, 52 ],
    [ 485, 2, 50 ],
    [ 482, 2, 31 ],
    [ 522, 2, 35 ],
    [ 527, 2, 52 ],
    [ 588, 2, 188 ],
    [ 526, 2, 101 ],
    [ 487, 2, 101 ],
    [ 504, 2, 111 ],
    [ 525, 2, 147 ],
    [ 518, 2, 161 ],
    [ 524, 2, 181 ],
    [ 511, 2, 186 ],
    [ 499, 2, 151 ],
    [ 481, 2, 144 ],
    [ 504, 2, 166 ],
    [ 585, 2, 109 ],
    [ 592, 2, 95 ],
    [ 602, 2, 100 ],
    [ 615, 2, 65 ],
    [ 597, 2, 41 ],
    [ 631, 2, 39 ],
    [ 694, 2, 39 ],
    [ 705, 2, 56 ],
    [ 705, 2, 88 ],
    [ 705, 2, 113 ],
    [ 713, 2, 110 ],
    [ 552, 2, 165 ],
    [ 585, 2, 162 ],
    [ 565, 2, 139 ],
    [ 541, 2, 129 ],
    [ 549, 2, 100 ],
    [ 565, 2, 86 ],
    [ 567, 2, 68 ],
    [ 535, 2, 70 ],
    [ 551, 2, 46 ],
    [ 591, 2, 63 ],
    [ 620, 2, 85 ],
    [ 658, 2, 43 ],
    [ 682, 2, 69 ],
    [ 674, 2, 95 ],
    [ 681, 2, 120 ],
    [ 700, 2, 142 ],
    [ 710, 2, 170 ],
    [ 710, 2, 204 ],
    [ 662, 2, 231 ],
    [ 651, 2, 267 ],
    [ 640, 2, 245 ],
    [ 641, 2, 215 ],
    [ 597, 2, 237 ],
    [ 574, 2, 231 ],
    [ 602, 2, 207 ],
    [ 625, 2, 187 ],
    [ 653, 2, 187 ],
    [ 677, 2, 163 ],
    [ 645, 2, 123 ],
    [ 631, 2, 140 ],
    [ 614, 2, 118 ],
    [ 556, 2, 184 ],
    [ 579, 2, 214 ],
]