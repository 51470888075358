export const coinPositions = [
    [ 0, 4, 4 ],
    [ - 86, 4, - 7 ],
    [ - 12, 4, 9.5 ],
    [ 104, 4, 37 ],
    [ 59, 4, 82 ],
    [ - 101, 4, 58 ],
    [ - 70, 4, 90 ],
    [ - 12, 4, 110 ],
    [ 96, 4, - 32 ],
    [ 77, 4, - 49 ],
    [ 10, 4, - 69 ],
    [ - 31.5, 4, - 69 ],
    [ - 120, 4, - 96 ],
    [ 13, 4, 8.4 ],
    [ 616, 4, 145 ],
    [ 560, 4, 258 ],
    [ 481, 4, 236 ],
    [ 598, 4, 144.5 ],
    [ 590, 4, 134 ],
    [ - 688, 4, - 56 ],
]